import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function getEmailTemplateBody({ clinicId, emailType }) {
  try {
    const res = await axios.get(`/clinics/${clinicId}/preview_email_template?email_template=${emailType}`, {
      headers: { Accept: 'application/json' },
    });
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}
